<template>
  <div>
    <b-card title="睡眠报告">
      <div class="custom-search">
        <!-- advance search input -->
        <b-row class="mb-2">
          <b-col md="3">
            <b-form-group label-cols="4" label-cols-lg="2" label-size="lg" label="姓名" label-for="input-lg">
              <b-form-input id="input-lg" v-model="searchUserName" placeholder="请输入姓名" />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label-cols="4" label-cols-lg="3" label-size="lg" label="入住信息" label-for="input-lg">
              <b-form-input id="input-lg" v-model="resourceName" placeholder="请输入资源名" />
            </b-form-group>
          </b-col>
          <b-col md="6" class="tRight">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="pagePerson(true)">
              搜索
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div>
        <vue-good-table ref="personTable" :columns="columns" :rows="rows" :rtl="direction"
          :sort-options="{enabled: false,}" :search-options="{enabled: true,externalQuery: searchTerm }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }" :pagination-options="{
            enabled: true,
            perPage:pageSize
          }" theme="my-theme">
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'sex'">
              {{ getSexName(props.row.sex) }}
            </span>
            <span v-else-if="props.column.field === 'status'">
              {{ getStatusName(props.row.status) }}
            </span>
            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm" @click="onInfoClick(props.row)">
                <feather-icon icon="VoicemailIcon" class="mr-50"/>
                <span class="align-middle">查看</span>
              </b-button>
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="tCenter">
              <b-pagination v-model="currentPage" :total-rows="total" :per-page="pageSize" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @change="currentPageHandleChange">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-card>
  </div>

</template>

<script>
import {
  BAvatar,BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BButton,BDropdown,BDropdownItem,BPagination
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import request from '@/api/request'
import vSelect from 'vue-select'

export default {
  components: {
    BCard,
    BAvatar,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BForm,
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      logDetail:{},
      searchTerm:'',
      dir: false,
      searchUserName:'',
      resourceName:'',
      logTypeOptions: [
        {
          value: 0,
          description: ''
        }
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      rows:[],
      columns: [
        {
          label: '编号',
          field: 'id',
        },
        {
          label: '姓名',
          field: 'userName',
        },
        {
          label: '性别',
          field: 'sex',
        },
        {
          label: '入住信息',
          field: 'resourceName',
        },
        {
          label: '备注',
          field: 'remark',
        },
        {
          label: '睡眠报告',
          field: 'action',
        }
      ],
      sexOptions: [
        { label:'男', value: 0 },
        { label: '女', value: 1 },
      ],

      personStatusOptions: [
        { label: '已入住', value: 0 },
        { label: '已离院', value: 1 },
      ],

    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created(){
    this.pagePerson(true)
  },
  methods: {
    getSexName(sex){
      console.log('sex',sex)
      return this.sexOptions[sex].label
    },
    pagePerson(resetPage){
      if(resetPage)
        this.currentPage = 1
      request.post('tob/tenant/sleepReport/page', {
        userName: this.searchUserName,
        resourceName:this.resourceName,
        pageSize: this.pageSize,
        currentPage: this.currentPage
      }).then(res => {
        console.log('RES',res)
        if (res.data.success) {
          this.rows = res.data.data.data
          this.total = res.data.data.count
          console.log('total',this.total)
        }
      })
    },
    currentPageHandleChange(currentPage){
      this.currentPage = currentPage
      this.pagePerson()
    },

    getStatusName(val){
      if (val==undefined|val==null)  {
        return val;
      }
      for(const enu of this.personStatusOptions) {
        if (enu.value==val){
          return enu.label;
        }
      }
      return val;
    },

    onInfoClick(row){
      this.$router.push({
        name: 'sleep-sleepReportInfo',
        query: { rowInfo: row }
      })
    }

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
